body {
  padding-top: 0; /* Remove fixed padding */
}

.main-content {
  min-height: calc(100vh - 76px);
}

.App {
  text-align: center;
}

/* Remove or comment out the conflicting styles */
/* .App-logo, .App-header, etc. */
