.product-card {
  margin-bottom: 20px;
  height: 100%;
}

.product-card img {
  height: 200px;
  object-fit: cover;
}

/* Hero section styles */
.hero-section {
  position: relative;
  min-height: 100vh;
  width: 100%;
  color: white;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Slightly lighter overlay */
  z-index: 2;
}

.hero-content {
  position: relative;
  z-index: 3;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 2rem 0;
}

.hero-text-container {
  background: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  border-radius: 10px;
  max-width: 600px;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-subtitle {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.hero-description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Golden glowing button */
.golden-btn {
  background: linear-gradient(145deg, #FFD700, #FFA500);
  border: none;
  color: #000;
  font-weight: 600;
  padding: 1rem 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.golden-btn:hover {
  background: linear-gradient(145deg, #FFA500, #FFD700);
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.8);
  color: #000;
}

.golden-btn:active {
  transform: translateY(0);
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.6);
}

/* Responsive styles for hero section */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
  }
  
  .hero-description {
    font-size: 1rem;
  }
  
  .hero-text-container {
    padding: 1.5rem;
    margin: 0 1rem;
  }

  .hero-image {
    height: 100vh;
    object-position: center;
  }
}

/* Contact form styles */
.contact-form {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-info {
  background-color: #e9ecef;
  padding: 2rem;
  border-radius: 8px;
}

/* Button styles */
button, .submit-btn, .whatsapp-btn {
  transition: transform 0.2s ease, background-color 0.3s;
}

button:hover, .submit-btn:hover {
  transform: scale(1.1);
}

.submit-btn:hover {
  transform: translateY(-2px);
}

/* WhatsApp button */
.whatsapp-btn {
  background-color: #25d366;
  border: none;
  transition: background-color 0.3s, transform 0.2s;
}

.whatsapp-btn:hover {
  background-color: #20c659;
  transform: scale(1.1);
}

/* Fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

h2, h3, p {
  animation: fadeIn 1s ease-in-out;
}

/* Card styles */
.card, .product-card {
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: scale(1.05);
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

/* Navbar styles */
.custom-navbar {
  background-color: white !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navbar-brand {
  font-weight: bold;
  color: #343a40 !important;
}

.nav-link {
  color: #495057 !important;
  cursor: pointer;
  padding: 0.5rem 1rem !important;
  margin: 0 0.2rem;
  transition: all 0.3s ease;
}

.nav-link:hover {
  color: #007bff !important;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #007bff;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.nav-link:hover::after {
  width: 100%;
}

/* Mobile responsiveness */
@media (max-width: 991.98px) {
  .navbar-collapse {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .nav-link {
    margin: 8px 0;
  }

  .navbar-collapse {
    padding: 1rem 0;
  }
  
  .nav-link {
    padding: 0.5rem 0 !important;
    margin: 0.2rem 0;
  }
}

.special-image {
  grid-column: span 2; /* Makes special images take up more space */
  /* Add any other special styling you want */
}

/* Beverage card styles */
.beverage-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  border: none;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.beverage-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.beverage-image {
  height: 200px;
  object-fit: cover;
  width: 100%;
}

.beverage-card .card-body {
  padding: 1.25rem;
  background: white;
}

.beverage-card .card-title {
  font-weight: 600;
  color: #333;
  margin-bottom: 0.75rem;
}

.beverage-card .card-text {
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .beverage-card {
    margin-bottom: 1rem;
  }
  
  .beverage-image {
    height: 180px;
  }
}

/* Products page styles */
.products-container {
  padding-top: 80px; /* Adjust this value based on your navbar height */
  margin-bottom: 2rem;
}

.products-heading {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 600;
  color: #333;
  padding: 1rem 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .products-container {
    padding-top: 70px; /* Slightly smaller padding for mobile */
  }

  .products-heading {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
}

/* About page specific styles */
.about-container {
  padding-top: 76px; /* Adjust based on navbar height */
}

@media (max-width: 768px) {
  .about-container {
    padding-top: 60px;
  }
  
  .hero-content h1 {
    font-size: 2.5rem !important;
  }
  
  .hero-content p {
    font-size: 1.2rem !important;
  }
  
  .stats-card {
    margin: 0.5rem 0;
  }
}

/* Founder image styles */
.founder-image-container {
  text-align: center;
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 15px;
  transition: transform 0.3s ease;
  margin: 1rem;
}

.founder-image-container:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.founder-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  border: 5px solid white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
}

.founder-details {
  margin-top: 1.5rem;
}

.founder-details h3 {
  color: #2c3e50;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.founder-details p {
  color: #666;
  font-size: 1.1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .founder-image {
    width: 250px;
    height: 250px;
  }
  
  .founder-image-container {
    padding: 1rem;
  }
}

.founder-wrapper {
  text-align: center;
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 15px;
  transition: transform 0.3s ease;
  margin: 1rem;
}

.founder-wrapper:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.founder-details {
  margin-top: 1.5rem;
}

.founder-details h3 {
  color: #2c3e50;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.founder-details p {
  color: #666;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .founder-wrapper {
    padding: 1rem;
  }
  
  .founder-image-container {
    width: 250px !important;
    height: 250px !important;
  }
}

.image-container {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
